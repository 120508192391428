<template>
  <div>
    <div class="pax-widget" :id="'paxwidget-' + params.data.id">
      <div class="position-relative">
        <div class="row mx-0">
          <div class="added-pax">
            <div class="d-flex flex-wrap">
              <span class="min-pax">{{ min }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <span class="durchfuehr-min-pax">{{ drfMinPax }}</span>
            </div>
          </div>

          <div class="added-pax position-relative">
            <div class="d-flex flex-wrap">
              <span class="kalk-pax">{{ kalk || 0 }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <span class="erwartete-pax">{{ erwPax }}</span>
            </div>
          </div>

          <div class="ist-pax" :class="istBgColor">
            <div class="ist-pax-text">
              {{ ist }}
            </div>
            <i v-if="istBgColor === 'greenalert'" class="flaticon2-exclamation ist-pax-alert"></i>
          </div>
          <div class="added-pax position-relative">
            <span class="plus-sign bg-white">+</span>
            <div class="d-flex flex-wrap">
              <span class="opt-pax" :class="optAndAnfBgColor">{{ opt }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <span class="anf-pax" :class="optAndAnfBgColor">{{ anf }}</span>
            </div>
          </div>
          <div class="pax-num bg-secondary rounded-right">
            <div>{{ max }}</div>
          </div>
        </div>
        <b-tooltip :target="'paxwidget-' + params.data.id" boundary-padding="0" placement="bottom">
          <div class="d-flex">
            <div class="d-flex flex-wrap">
              <span>Min</span>
              <span>DMIN</span>
            </div>
            <div class="d-flex flex-wrap">
              <span>KALK</span>
              <span>ERW</span>
            </div>
            <div>{{ $t('PAX.IST') }}</div>

            <div class="d-flex flex-wrap pl-3">
              <span>{{ $t('PAX.OPT') }}</span>
              <span>{{ $t('PAX.ANF') }}</span>
            </div>
            <div class="">{{ $t('PAX.MAX') }}</div>
          </div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaxWidget',
  data() {
    return {
      isHover: false,
      mouseOverLast: false,
      mouseOverDelay: 800,
      mouseOverTimeout: null,
    };
  },

  computed: {
    ist() {
      return this.params.data.reisetermin.pax?.ist || 0;
    },
    min() {
      return this.params.data.reisetermin.pax?.min || 0;
    },
    max() {
      return this.params.data.reisetermin.pax?.max || 0;
    },
    kalk() {
      return this.params.data.reisetermin.metadata.kalkPax || 0;
    },
    opt() {
      return this.params.data.reisetermin.pax?.opt || 0;
    },
    anf() {
      return this.params.data.reisetermin.pax?.anf || 0;
    },
    war() {
      return this.params.data.reisetermin.pax?.war || 0;
    },
    vor() {
      return this.params.data.reisetermin.pax?.vor || 0;
    },
    erwPax() {
      return this.params.data.reisetermin.metadata.erwartetePax || 0;
    },
    drfMinPax() {
      return this.params.data.reisetermin.metadata.durchfuehrbareMinPax || 0;
    },
    istBgColor() {
      return this.ist === 0
        ? 'gray'
        : this.ist < this.min && this.ist !== 0
        ? 'red'
        : this.ist >= this.min && this.ist < this.kalk
        ? 'yellow'
        : this.ist >= this.kalk && this.ist < this.max
        ? 'green'
        : this.ist === this.max
        ? 'darkgreen'
        : 'greenalert';
    },
    optAndAnfBgColor() {
      if (this.istBgColor === 'red' && (this.opt > 0 || this.anf > 0)) {
        return 'light-red';
      } else if (this.istBgColor === 'yellow' && (this.opt > 0 || this.anf > 0)) {
        return 'light-yellow';
      } else if (this.istBgColor === 'green' && (this.opt > 0 || this.anf > 0)) {
        return 'light-green';
      } else {
        return 'light-grey';
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.red {
  background-color: $danger;
}
.light-red {
  background-color: $danger-light;
}
.yellow {
  background-color: $warning;
}
.light-yellow {
  background-color: $warning-light;
}
.darkgreen {
  background-color: darken($success, 10%);
}
.light-darkgreen {
  background-color: $success-light;
}
.greenalert {
  background-color: $success;
}
.green {
  background-color: $success;
}
.gray {
  background-color: $gray-500;
}
.light-grey {
  background-color: $secondary;
}

.pax-widget {
  text-align: center;
  width: 140px;
  height: 29.5px;
  white-space: nowrap;
  margin-bottom: 5px;
}

.min-pax {
  background-color: $secondary;
  border-bottom: 2px solid #ffffff;
  width: 22px;
  padding: 0, 2px, 0, 0px;
  font-size: 0.9rem;
  border-top-left-radius: 5px;
}

.durchfuehr-min-pax {
  background-color: $secondary;
  border-bottom: 2px solid $gray-300;

  width: 22px;
  padding: 0, 2px, 0, 2px;
  font-size: 0.9rem;
  border-bottom-left-radius: 5px;
}

.kalk-pax {
  background-color: $secondary;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;

  width: 22px;
  padding: 0, 2px, 0, 0px;
  font-size: 0.9rem;
}

.erwartete-pax {
  background-color: $secondary;
  border-bottom: 2px solid $gray-300;
  border-right: 2px solid #ffffff;

  width: 22px;
  padding: 0, 2px, 0, 2px;
  font-size: 0.9rem;
}

.ist-pax-text {
  display: inline-block;
  font-size: 1.5rem;
}

.ist-pax-alert {
  color: $danger;
  font-size: 1.35rem;
}

.ist-pax {
  text-align: center;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.added-pax {
  vertical-align: middle;
  border-left: 2px solid #ffffff;
}

.plus-sign {
  position: absolute;
  text-align: center;
  left: -7px;
  top: 12px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  font-size: 1rem;
  line-height: 11px;
  box-shadow: 0 0.2rem 0.4rem 0.2rem rgb(0 0 0 / 20%);
  font-weight: bold;
}

.opt-pax {
  border-bottom: 2px solid #ffffff;
  border-right: 1px solid #ffffff;
  width: 22px;
  padding: 0, 2px, 0, 0px;
  font-size: 0.9rem;
}

.anf-pax {
  border-bottom: 2px solid $gray-300;
  border-right: 1px solid #ffffff;
  width: 22px;
  padding: 0, 2px, 0, 2px;
  font-size: 0.9rem;
}

.pax-num {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pax-num:last-child {
  margin-right: 0px;
  margin-left: 1px;
}

.vor-pax {
  background-color: $secondary;
  border-right: 2px solid #ffffff;
  width: 20px;
  padding: 0, 2px, 0, 2px;
}

.war-pax {
  background-color: $secondary;
  width: 20px;
  padding: 0, 2px, 0, 2px;
}

.tooltip {
  opacity: 1 !important;
}
</style>
